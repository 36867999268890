import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField,Button,Checkbox,FormControlLabel} from '@material-ui/core';
import axios from 'axios';
import Chart from 'react-google-charts';

const sex = ["Female", "Male", "Other"];

const race = ["N/A", "Asian", "Black", "White", "Hispanic", "Native/Indigenous", "Pacific Islander", "Mixed", "Other"];
const yearsout = ["1-2", "3-4", "5-9", "10+", "K-JD", "Don't use as a factor"];
const schooltype = ["Private", "Public", "Don't use as a factor"];
const major = ["Liberal Arts", "STEM", "Other"];

const t14 = ["yale","stanford","harvard", "chicago", "columbia", "nyu", "penn", "michigan", "berkeley","uva", "duke", "northwestern", "cornell", "gulc"];

require('@material-ui/core/');

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 180,
    },
    dense: {
        marginTop: 0,
        marginBottom: 5,
    },
    menu: {
        width: 180,
    },
});


class CandidateForm extends React.Component {


    /*constructor(props){
        super(props);
        this.handleChange=this.handleFormSubmit.bind(this);
        this.handleChangeCheck=this.handleChangeCheck.bind(this);
        this.shouldBlockButton=this.shouldBlockButton.bind(this);
    }*/
    state = {

        urm: false,
        ed: false,
        feewaiver: false,
        nontrad: false,
        international: false,
        tfa: false,
        lgbt: false,
        military: false,
        sex: 'Female',
        race: 'White',
        yearsout: 'K-JD',
        schooltype: 'Public',
        lsat: 120,
        gpa: 3.00,
        major: 'Liberal Arts',
        results: {},
        data: [["School", "Score"],
            ["harvard", 0],
            ["stanford", 0],
            ["yale", 0],
            ["chicago", 0],
            ["columbia", 0],
            ["nyu", 0],
            ["penn", 0],
            ["michigan", 0],
            ["uva", 0],
            ["berkeley", 0],
            ["duke", 0],
            ["northwestern", 0],
            ["cornell", 0]],
        submitted: false,
        goodgpa: false,
        goodlsat: false
    };


    handleChangeCheck = name => async (event) => {

        let prev = this.state[name];
        await this.setState({[name]: !prev});


    };

    handleChange = name => async(event) => {


        if ((name === "gpa" && (1 <= event.target.value <= 4.0)) || (name === "lsat" && (120 <= event.target.value <= 180))) {
            if (name === "gpa") {
                this.setState({'goodgpa': true});
            }
            if (name === "lsat") {
                this.setState({'goodlsat': true});

            }
            this.setState({[name]: event.target.value});
        }

        else if ((name ==="gpa")){
            await this.setState({'goodgpa':false});

        }
        else if ((name==="lsat")){
            await this.setState({"goodlsat":false});
        }

        this.setState({[name]: event.target.value});
    };


    shouldComponentUpdate(nextProps, nextState) {
        return this.state.dropdownVal === nextState.dropdownVal;


    }

    shouldBlockButton(){
        return !(this.state.goodgpa && this.state.goodlsat);
    }

    handleFormSubmit = async (formSubmitEvent) => {

        formSubmitEvent.preventDefault();

        if (this.state.goodgpa && this.state.goodlsat) {


            await axios.post("https://kxv0j8ic8j.execute-api.us-west-2.amazonaws.com/production/predict", this.state).then(res => {
                this.setState({results: res.data})
            }).catch(err => console.warn(err));


            let data_submit = [['School', "Score"]];
            let data_r = this.state['results'];

            for (let sc_name in t14) {
                let entry = [t14[sc_name]];
                let proba = parseFloat(data_r[t14[sc_name]]) * 100;
                entry = entry.concat([proba]);
                data_submit.push(entry);
            }

            this.setState({submitted: true});
            await this.setState({data: data_submit});
        }
    };


    render() {
        const {classes} = this.props;
        const {urm, ed, feewaiver, nontrad, international, tfa, lgbt, military} = this.state


        return (

            <div>
                <form className={classes.container} noValidate autoComplete="off"
                      onSubmit={this.handleFormSubmit} style={{display: 'flex', justifyContent: 'center'}}
                      align="center"
                      id="mainform">
                    <div><TextField
                        required
                        id="gpa"
                        label="GPA"
                        //helperText="On a 4.00 scale"
                        placeholder="Enter your GPA"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange('gpa')}
                        error={(isNaN(this.state.gpa) || this.state.gpa > 4.0 || this.state.gpa < 0.0)}
                        helperText={(isNaN(this.state.gpa) || this.state.gpa > 4.0 || this.state.gpa < 0.0) ? 'Enter a GPA on the 4.0 scale' : ''}

                    />


                        <TextField
                            required
                            id="lsat"
                            label="LSAT"
                            placeholder="Enter your LSAT"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange('lsat')}
                            error={(isNaN(this.state.lsat) || this.state.lsat > 180 || this.state.lsat < 120)}
                            helperText={this.state.lsat > 180 || this.state.lsat < 120 ? 'Enter a valid LSAT score' : ''}

                        />

                        <TextField

                            id="select-sex"
                            select
                            label="Sex"
                            className={classes.textField}
                            //value={this.state.sex}
                            placeholder=""
                            onChange={this.handleChange('sex')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"

                        >
                            {sex.map(option => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}


                        </TextField>

                        <TextField
                            id="standard-race"
                            select
                            label="Race"
                            className={classes.textField}
                            placeholder=""
                            onChange={this.handleChange('race')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"

                        >
                            {race.map(option => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}


                        </TextField>

                        <TextField
                            id="select-years"
                            select
                            label="Years Out"
                            className={classes.textField}
                            placeholder=""
                            onChange={this.handleChange('yearsout')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"

                        >
                            {yearsout.map(option => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}


                        </TextField>

                        <TextField

                            id="select-major"
                            select
                            label="Major"
                            className={classes.textField}

                            placeholder=""
                            onChange={this.handleChange('major')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"

                        >
                            {major.map(option => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}


                        </TextField>

                        <TextField


                            id="standard-select-undergrad"
                            select
                            label="Undergrad Type"
                            className={classes.textField}
                            //value={this.state.sex}
                            placeholder=""
                            onChange={this.handleChange('schooltype')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"

                        >
                            {schooltype.map(option => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </TextField>
                    </div>
                    <div id="checkboxes-demog">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={military}
                                    onChange={this.handleChangeCheck('military')}
                                    value="false"
                                />
                            }
                            label="Military"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={international}
                                    onChange={this.handleChangeCheck('international')}
                                    value="false"
                                />
                            }
                            label="International"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={ed}
                                    onChange={this.handleChangeCheck('ed')}
                                    value="false"
                                />
                            }
                            label="Early Decision"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={tfa}
                                    onChange={this.handleChangeCheck('tfa')}
                                    value="false"
                                />
                            }
                            label="Teach For America"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={nontrad}
                                    onChange={this.handleChangeCheck('nontrad')}
                                    value="false"
                                />
                            }
                            label="Non-Traditional Student"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={urm} onChange={this.handleChangeCheck('urm')} value="false"/>
                            }
                            label="URM"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={lgbt} onChange={this.handleChangeCheck('lgbt')} value="false"/>
                            }
                            label="LGBT+"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={feewaiver}
                                    onChange={this.handleChangeCheck('feewaiver')}
                                    value="false"
                                />
                            }
                            label="Fee Waiver"
                        />
                        <div id="button" style={{display: 'flex', justifyContent: 'center'}}>
                            <Button variant="contained" color="primary" className={classes.button} value="submit"
                                    disabled={this.shouldBlockButton()} type="submit">
                                ANALYZE
                            </Button>

                        </div>


                    </div>

                </form>

                <div id="chart" align="center">

                    {this.state.submitted ?

                        <Chart
                            width={450}
                            height={300}
                            chartType="BarChart"
                            loader={<div>Loading Chart</div>}
                            data={this.state.data}
                            options={{
                                title: 'Law Schools',
                                textStyle: {
                                    fontSize: 12,
                                    fontName: 'Roboto'
                                },
                                hAxis: {
                                    minValue: 0, maxValue: 100,
                                    titleTextStyle: {color: '#333'},
                                    format: '#\'%\''
                                },
                                vAxis: {minValue: 0},
                                chartArea: {width: '70%', height: '80%'},
                                legend: {position: 'none'},
                            }}
                        /> : null}
                </div>
            </div>
        );
    }
}

CandidateForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CandidateForm);
