import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
});

function Contact(props) {
    const {classes} = props;

    return (
        <div>
            <Paper className={classes.root} elevation={3} align="center">
                <Typography variant="h5" component="h3">
                    Contact
                </Typography>
                <Typography variant = "body1">
                    Feel free to contact me at the mailbox  <a
                    href="mailto:&#104;&#097;&#100;&#108;&#101;&#121;&#046;&#114;&#111;&#108;&#097;&#110;&#100;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;">&#104;&#097;&#100;&#108;&#101;&#121;&#046;&#114;&#111;&#108;&#097;&#110;&#100;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;</a>
                </Typography>



            </Paper>
        </div>
    );
}

Contact.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contact);