import React from 'react';
import Form from './components/Form';
import Terms from './components/Terms';
import TitleBar from './components/Bar';
import About from './components/About';
import Contact from "./components/Contact";
import Privacy from './components/Privacy';
import Description from "./components/Description";
import {Helmet} from 'react-helmet';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';


const medStyle = {
    bottom: 0,
    marginTop: '50px',
    padding: 1,
};

const bottomBar = {
    bottom: 0,
    marginTop: '50px',
    padding: 1,
    width: '70%',
    align:'center'
};
class App extends React.Component {


    render() {
        return (

            <div className="application">
                <Helmet>
                    <title> SchoolPredict.com | Law School Calculator
                    </title>
                    <meta name="theme-color" content="#008f68"/>
                    <meta name="description" content="Tool for analyzing law school applications"/>
                    <meta name="keywords" content="lawschool,gradschool,LSAT,GPA,T14"/>

                    <body className="dark"/>
                </Helmet>
                <TitleBar/>
                <span> </span>

                <div id="form" align="center"><Form/>
                </div>

                <div align="center" style={medStyle}>
                    <div id="description" align="center">
                        <Description/>

                    </div>
<Paper style={bottomBar} elevation={8}>
                    <div align="center">
                        <div id="terms" align="center">

                        <ExpansionPanel align="center">
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />} align="center">
                                    <Typography >
                                    Terms of Use
                                    </Typography>
                                </ExpansionPanelSummary>
                            <Terms/>

                        </ExpansionPanel>
                        </div>

                        <div id="privacy" align="center">
                            <ExpansionPanel align="center">
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>
                                    <Typography>
                                Privacy
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <Privacy/>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                        <div id="about"align="center">
                            <ExpansionPanel align="center">
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>
                                    About
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <About/>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                        <div id="contact" align="center">
                            <ExpansionPanel align="center">
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>
                                   Contact
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <Contact/>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>

                    </div>
</Paper>
                </div>
            </div>

        );
    }
}


export default App;
