import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
});

function About(props) {
    const {classes} = props;

    return (
        <div>
            <Paper className={classes.root} elevation={3}>
                <Typography variant="h5" component="h3">
                    About Us
                </Typography>
                <Typography variant = "body1">
                    SchoolPredict.com is owned and operated by James Gilbert. Originally started as a side-project after doing research for a friend who was trying to get into law school.
                    Subsequently, the original model was enhanced for a broader analysis of law schools and candidate information.
                </Typography>



            </Paper>
        </div>
    );
}

About.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(About);