import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {AppBar, Toolbar, Typography, IconButton} from '@material-ui/core';


const styles = {
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
};

function TitleBar(props) {
    const {classes} = props;
    return (
        <div className={classes.root} align="center">
            <AppBar position="static">
                <Toolbar>
                    <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
                    </IconButton>
                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        Law School Admissions Calculator
                    </Typography>


                </Toolbar>

            </AppBar>

        </div>
);
}

TitleBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TitleBar);