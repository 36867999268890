import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,

    },
});

function Terms(props) {
    const {classes} = props;

    return (
        <div>
            <Paper className={classes.root} elevation={3}  style={{maxHeight: 200, overflow: 'auto'}}>
                <Typography variant="h5" component="h3">
                    Privacy Policy
                </Typography>


                Privacy Policy

                This SchoolPredict.com Privacy Policy describes how your personal information is collected, used, and shared when you
                visit or make a submission from SchoolPredict.com (the “Site”).

                <h5>PERSONAL INFORMATION WE COLLECT</h5>


                When you visit the Site, we automatically collect certain information about your device, including
                information about your web browser, IP address, time zone, and some of the cookies that are installed on
                your device. Additionally, as you browse the Site, we collect information about the individual web pages
                that you view, what websites or search terms referred you to the Site, and information about how you
                interact with the Site. We refer to this automatically-collected information as “Device Information.”

                For a better experience, while using our Service, we may ask (not require) you to provide us with
                certain personally identifiable information, including but not limited to: LSAT Score, GPA, Race, Sex,
                Type of Undergraduate University, Undergraduate Major, Years Since Graduating from Undergraduate
                Education, Military Status ("Military"), Minority Self-Identification ("URM"), Sexuality
                Self-Identification ("LGBT+"), Fee Waiver Eligible ("Fee Waiver"), Citizenship Status ("International"),
                Teach For America Experience ("TFA"). We refer to this information as “Profile Information.”

                We collect Device Information using the following technologies:
                - “Log files” track actions occurring on the Site, and collect data including your IP address, browser
                type, Internet service provider, referring/exit pages, and date/time stamps.
                - “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you
                browse the Site.

                When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device
                Information and Order Information.

                <h5>HOW DO WE USE YOUR PERSONAL INFORMATION?</h5>

                We use the Device Information that we collect to help us screen for potential risk and fraud (in
                particular, your IP address), and more generally to improve and optimize our Site (for example, by
                generating analytics about how visitors browse and interact with the Site, and to assess the success of
                our marketing and advertising campaigns).

                We use the Profile Information that we collect to calculate predictive model scores and display the
                scores to you.

                <h5>SHARING YOUR PERSONAL INFORMATION</h5>


                We may share your Personal Information to comply with applicable laws and regulations, to respond to a
                subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our
                rights.

                We also use Google Analytics to help us understand how our customers use the Site
                --you can read more about how Google uses your Personal Information here: <a href="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</a>.
                You can also opt-out of Google Analytics here:  <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
                BEHAVIOURAL ADVERTISING
                As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.  For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
                You can opt out of targeted advertising by going to:
                <ul align="center">
                     <a href="https://www.google.com/settings/ads/anonymous"> https://www.google.com/settings/ads/anonymous </a>
                </ul>
                Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at:  http://optout.aboutads.info/

                <h5>DO NOT TRACK</h5>
                Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track
                signal from your browser.

                <h5>YOUR RIGHTS</h5>
                If you are a European resident, you have the right to access personal information we hold about you and
                to ask that your personal information be corrected, updated, or deleted. If you would like to exercise
                this right, please contact us through the contact information below.

                Additionally, if you are a European resident we note that we are processing your information in order to
                fulfill contracts we might have with you (for example if you make an order through the Site), or
                otherwise to pursue our legitimate business interests listed above. Additionally, please note that your
                information will be transferred outside of Europe, including to Canada and the United States.
                <h5>DATA RETENTION</h5>
                When you use the Site, we do not maintain or store any of your Profile Information in our records.

                <h5>MINORS</h5>
                The Site is not intended for individuals under the age of 13.

                <h5>CHANGES</h5>
                We may update this privacy policy from time to time in order to reflect, for example, changes to our
                practices or for other operational, legal or regulatory reasons.

                <h5>CONTACT US</h5>
                For more information about our privacy practices, if you have questions, or if you would like to make a
                complaint, please contact us by e-mail at <a
                href="mailto:&#104;&#097;&#100;&#108;&#101;&#121;&#046;&#114;&#111;&#108;&#097;&#110;&#100;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;">&#104;&#097;&#100;&#108;&#101;&#121;&#046;&#114;&#111;&#108;&#097;&#110;&#100;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;</a>
            </Paper>
        </div>
    );
}

Terms.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Terms);